import { Routes } from '@angular/router';
import { RedirectComponent } from './redirect/redirect.component';
import { WelcomingComponent } from './welcoming/welcoming.component';
import { authGuard } from './core/guards/auth.guard';
import { AcceptInvitationComponent } from './accept-invitation/accept-invitation.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { DefaultNotificationSettingsComponent } from './default-notification-settings/default-notification-settings.component';
import { notAuthenticatedGuard } from './core/guards/not-authenticated.guard';
import { NotFoundPageComponent } from './not-found-page/not-found-page.component';

export const routes: Routes = [
  { path: '', component: RedirectComponent },
  {
    path: 'welcoming',
    data: { breadcrumb: 'Welcoming' },
    component: WelcomingComponent,
    canActivate: [notAuthenticatedGuard]
  },
  {
    path: 'auth',
    data: { breadcrumb: 'Auth' },
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
    canActivate: [notAuthenticatedGuard]
  },
  {
    path: 'workspace',
    data: { breadcrumb: 'Workspace' },
    loadChildren: () => import('./workspace/workspace.module').then(m => m.WorkspaceModule),
    canActivate: [authGuard]
  },
  {
    path: 'i/:code/:invitation_id/:user_type',
    data: { invitation: 'dashboard' },
    component: AcceptInvitationComponent
  },
  { path: 'reset-password', redirectTo: 'auth/forgot-password' },
  { path: 'terms-and-conditions', component: TermsAndConditionsComponent },
  { path: 'default-notification-settings', component: DefaultNotificationSettingsComponent },
  { path: '**', pathMatch: 'full', component: NotFoundPageComponent }
];
