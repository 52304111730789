import { Component, Inject, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { AuthService } from '../shared/services/auth.service';
import { KorviuLoaderComponent } from '../shared/components/korviu-loader/korviu-loader.component';

@Component({
  selector: 'app-redirect',
  standalone: true,
  imports: [RouterModule, KorviuLoaderComponent],
  templateUrl: './redirect.component.html',
  styleUrl: './redirect.component.scss'
})
export class RedirectComponent implements OnInit {
  constructor(
    private _router: Router,
    private _authService: AuthService
  ) {}

  public ngOnInit(): void {
    let navigateCommands: string[] = ['/', 'welcoming'];
    if (this._authService.userToken) {
      navigateCommands = ['/', 'workspace'];
    }
    this._router.navigate(navigateCommands);
  }
}
