import {
  Injectable,
  ComponentFactoryResolver,
  Injector,
  ApplicationRef,
  ComponentRef,
  Type,
  InjectionToken
} from '@angular/core';
import { Observable, of } from 'rxjs';

// Import the NZ_MODAL_DATA InjectionToken
import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal'; // Ensure this is correctly imported

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  private modalComponentRef: ComponentRef<any> | null = null;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private appRef: ApplicationRef
  ) {}

  open<T>(component: Type<T>, data?: any): Observable<void> {
    // Close any existing modal first
    this.close();

    // Create a custom injector for NZ_MODAL_DATA
    const injector = Injector.create({
      providers: [
        { provide: NZ_MODAL_DATA, useValue: data } // Provide NZ_MODAL_DATA with the passed data
      ],
      parent: this.injector
    });

    // Create a component factory
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);

    // Create a component reference with the custom injector
    this.modalComponentRef = componentFactory.create(injector);

    // Attach component to the application
    this.appRef.attachView(this.modalComponentRef.hostView);

    // Append the component to the body
    document.body.appendChild(this.modalComponentRef.location.nativeElement);

    return of(); // Returning an observable that completes immediately
  }

  close(): void {
    if (this.modalComponentRef) {
      // Detach view and remove the component from DOM
      this.appRef.detachView(this.modalComponentRef.hostView);
      this.modalComponentRef.destroy();
      this.modalComponentRef = null;
    }
  }
}
